import React from "react";

import {useAuth0} from "@auth0/auth0-react";
import {Avatar, Box, Button, Container, Typography} from "@mui/material";

const Authentication = () => {
    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();

    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin + '/login',
            }
        });

    function handleLoginIn() {
        loginWithRedirect();
    }

    function handleLoginOut() {
        logoutWithRedirect();
    }

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    return (
        <div className="nav-container">
            <Container>
                {isAuthenticated && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap={2}
                    >
                            <Button
                                ref={anchorRef}
                                id="composition-button"
                                aria-controls={open ? 'composition-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                            >
                                <Typography variant="p" color="secondary">
                                    {user.nickname}
                                </Typography>
                                <Avatar sx={{width: 32, height: 32, marginLeft: 1}}>
                                    <img
                                        src={user.picture}
                                        alt="Profile"
                                        className="nav-user-profile d-inline-block rounded-circle mr-3"
                                        width="50"
                                    />
                                </Avatar>
                            </Button>
                            <Button
                                id="qsLoginBtn"
                                color="secondary"
                                variant="contained"
                                onClick={handleLoginOut}
                            >
                                Uitloggen
                            </Button>
                    </Box>)}

                {!isAuthenticated && (
                    <Button
                        id="qsLoginBtn"
                        color="primary"
                        onClick={handleLoginIn}
                    >
                        Inloggen
                    </Button>)}
            </Container>
        </div>)
}
export default Authentication;
