import React, {useContext, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {GlobalStateContext} from "../../state/GlobalStateProvider";
import {
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Grid,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../common/Loading";
import {useReservationAPI} from "../../service/useReservationAPI";
import {useCatAPI} from "../../service/useCatAPI";
import GetErrorSnackbar from '../../common/Snackbar';


function CatNameInput({updateCurrentStep}) {
    const {globalState, updateGlobalState, setPartOfGlobalState} = useContext(GlobalStateContext);
    const [inputValue, setInputValue] = useState('');
    const [selectedOptions, setSelectedOptions] = useState({});
    const {getCatsInReservation} = useReservationAPI();
    const [cats, setCats] = useState([])
    const {getCats, createOrUpdateCat} = useCatAPI();
    const [reloadFlag, setReloadFlag] = useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [isLoading, setIsLoading] = useState(true);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    useEffect(() => {
        setIsLoading(true)
        if (globalState.reservationUuid) {
            // case existing reservation, possible not all owned cats are in reservation
            Promise.all([getCatsInReservation(globalState.reservationUuid), getCats()]).then(results => {
                const [catsInReservation, cats] = results;
                updateGlobalState({reservationMeta: {catsInReservationIds: catsInReservation}})
                setCats(cats)
            }).catch(() => handleFetchError('Katten voor reservatie konden niet opgehaald worden'))
                .finally(() => setIsLoading(false));
        } else {
            // case new reservation with existing person with existing cats
            getAllCatsAndAddToReservation()
        }
    }, [reloadFlag]);

    function handleFetchError(reason) {
        setSnackbarMessage(reason)
        setOpenSnackbar(true)
    }

    const getAllCatsAndAddToReservation = () => {
        getCats()
            .then(value => {
                if (value.length > 0) {
                    setCats(value);
                    // updateGlobalState({catsInReservationIds: catsInReservation: value});
                }
            })
            .catch(() => handleFetchError('Mijn katten konden niet worden opgehaald'))
            .finally(() => setIsLoading(false));
    }

    const handleAddOption = () => {
        if (inputValue && !cats.map(value => value.name).includes(inputValue)) {
            setSelectedOptions({...selectedOptions, [inputValue]: true});
            setInputValue('');

            setIsLoading(true)
            createOrUpdateCat({name: inputValue})
                .then(() => setReloadFlag(!reloadFlag))
                .catch(() => {
                    handleFetchError('Nieuwe kat kan niet toegevoegd worden, probeer opnieuw.')
                })
                .finally(() => {
                    setIsLoading(false)
                });
        }
    };

    const handleContinue = () => {
        updateCurrentStep(2)
    };

    const toggleCatForReservation = (id) => {
        const {reservationMeta} = globalState;

        const foundReservedCat = reservationMeta.catsInReservationIds.find(value => value === id);

        if (foundReservedCat === undefined) {
            const catToAdd = cats.find(cat => cat.id === id);
            if (catToAdd) {
                updateGlobalState({
                    reservationMeta: {
                        catsInReservationIds: [...reservationMeta.catsInReservationIds, catToAdd.id]
                    }
                });
            }
        } else {
            const catsLeft = reservationMeta.catsInReservationIds.filter(value => value !== id);
            setPartOfGlobalState('reservationMeta.catsInReservationIds', catsLeft);
        }
    };

    return(
        <>
            {isLoading && (<Loading/>)}
            {!isLoading && (
                <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{textAlign: 'center'}}>
                    <Grid item xs={12} sm={10} md={8} lg={6}>
                        <Card variant="outlined">
                            <CardContent>
                                <>
                                    <Typography variant="h6" color="secondary" gutterBottom sx={{mb: 2}}>
                                        Start hier om een nieuwe kat toe te voegen.
                                    </Typography>
                                    <TextField
                                        required
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        label="Naam van de kat"
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (<InputAdornment position="end">
                                                <Button onClick={handleAddOption} variant="contained" disabled={isLoading}><AddIcon/></Button>
                                            </InputAdornment>),
                                        }}
                                    />
                                    <TableContainer component={Paper} sx={{marginTop: 5}}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><b>Selecteer voor verblijf</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    cats.length > 0 ? (
                                                        cats.map((cat) => (
                                                            <TableRow key={cat.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                                <TableCell component="th" scope="row">
                                                                    {/*<PetsIcon color="primary" sx={{marginRight: '10px'}}/> {cat.name}*/}
                                                                    <Checkbox
                                                                        checked={globalState.reservationMeta.catsInReservationIds.some(value => value === cat.id)}
                                                                        onChange={() => toggleCatForReservation(cat.id)}
                                                                        name="spayedNeutered"
                                                                        color="primary"
                                                                    />
                                                                    {cat.name}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Voeg een kat toe
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            </CardContent>
                            <CardActions style={{marginBottom: '10px'}}>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Button onClick={() => handleContinue()} variant="contained"
                                            disabled={isLoading || globalState.reservationMeta.catsInReservationIds.length < 1}>
                                        Doorgaan naar kalender
                                    </Button>
                                </Grid>
                            </CardActions>
                        </Card>
                        <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar}/>
                    </Grid>
                </Grid>)}
        </>
    )
}


export default withAuthenticationRequired(CatNameInput, {
    onRedirecting: () => <Loading/>,
    returnTo: '/nieuwe-reservatie'
});
