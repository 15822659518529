import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../common/Loading";
import React, {useContext, useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {useReservationAPI} from "../../service/useReservationAPI";
import {toLocalDate} from "../../common/DateUtil";
import CheckInDate from "./reservationFlow/CheckInDate";
import CheckOutDate from "./reservationFlow/CheckOutDate";
import SelectStay from "./reservationFlow/SelectStay";
import CheckInHour from "./reservationFlow/CheckInHour";
import CheckOutHour from "./reservationFlow/CheckOutHour";
import {GlobalStateContext} from "../../state/GlobalStateProvider";
import GetErrorSnackbar from "../../common/Snackbar";

function NewReservationPlanner({updateCurrentStep}) {
    const {globalState, updateGlobalState} = useContext(GlobalStateContext);
    const [isLoading, setIsLoading] = useState(true);

    const {checkReservationAvailability, getAvailableStays, getPickUpDays, getDeliverDays, createReservation, updateReservation} = useReservationAPI();

    const [stays, setStays] = useState([]);
    const [pickUpDays, setPickUpDays] = useState([]);
    const [deliverDays, setDeliverDays] = useState([]);
    const [checkedStays, setCheckedStays] = useState([]);
    const [currentStep, setCurrentStep] = useState('STEP_1');

    const numberOfPets = 1;
    const reservationStart = new Date(2024, 7, 1);
    const reservationEnd = new Date(2025, 7, 31);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    function handleFetchError(reason) {
        setSnackbarMessage(reason)
        setOpenSnackbar(true)
    }

    useEffect(() => {
        if (globalState.reservationMeta.toDate) {
            checkAvailability();
        }
        setIsLoading(false);
    }, []);

    function checkAvailability() {
        setIsLoading(true)
        checkReservationAvailability(toLocalDate(globalState.reservationMeta.fromDate), toLocalDate(globalState.reservationMeta.toDate), numberOfPets)
            .then(() => {
                getAvailableStays(toLocalDate(globalState.reservationMeta.fromDate), toLocalDate(globalState.reservationMeta.toDate))
                    .then(value => setStays(value))
                    .catch(() => handleFetchError('Beschikbare verblijven ophalen mislukt'));

                getPickUpDays(toLocalDate(globalState.reservationMeta.toDate))
                    .then(value => setPickUpDays(value))
                    .catch(() => handleFetchError('Beschikbare check-in uren ophalen mislukt'));

                getDeliverDays(toLocalDate(globalState.reservationMeta.fromDate))
                    .then(value => setDeliverDays(value))
                    .catch(() => handleFetchError('Beschikbare check-out uren ophalen mislukt'));
            })
            .catch(() => handleFetchError('Reservatie beschikbaarheid ophalen mislukt'))
            .finally(() => setIsLoading(false));
    }

    const updateStays = (updatedStays) => {
        setCheckedStays(updatedStays);
    };


    const updateReservationStep = (step) => {
        setCurrentStep('STEP_' + step);

        if (step === 3) {
            checkAvailability();
        }

        if (step <= -1) {
            updateCurrentStep(1)
        }
    }

    const handleReserve = () => {
        setIsLoading(true)
        if (globalState.reservationUuid) {
            updateReservation(globalState.reservationUuid, globalState.reservationMeta)
                .then(reservationUuid => {
                    updateGlobalState({reservationUuid: reservationUuid});
                    updateGlobalState({lastSelectedDateInCalender: globalState.reservationMeta.fromDate})
                    updateCurrentStep(3)
                })
                .catch(() => handleFetchError('Reservatie updaten mislukt'))
                .finally(() => setIsLoading(false));
        } else {
            createReservation(globalState.reservationMeta)
                .then(reservationUuid => {
                    updateGlobalState({reservationUuid: reservationUuid});
                    updateGlobalState({lastSelectedDateInCalender: globalState.reservationMeta.fromDate})
                    updateCurrentStep(3)
                })
                .catch(() => handleFetchError('Reservatie aanmaken mislukt'))
                .finally(() => setIsLoading(false));
        }
    };

    return (
        <Grid container justifyContent="center" sx={{textAlign: 'center', marginLeft: 2}}>
            {isLoading && (<Loading/>)}
            {!isLoading && (<Grid item xs={8}>
                {currentStep === 'STEP_1' &&
                    (<CheckInDate reservationStart={reservationStart}
                                  reservationEnd={reservationEnd}
                                  updateReservationStep={updateReservationStep}/>)}

                {currentStep === 'STEP_2' &&
                    (<CheckOutDate reservationEnd={reservationEnd}
                                   updateReservationStep={updateReservationStep}/>)}

                {currentStep === 'STEP_3' &&
                    (<SelectStay availableStays={stays}
                                 checkedStays={checkedStays}
                                 updateStays={updateStays}
                                 updateReservationStep={updateReservationStep}/>)}

                {currentStep === 'STEP_4' &&
                    (<CheckInHour checkInHours={deliverDays}
                                  updateReservationStep={updateReservationStep}/>)}

                {currentStep === 'STEP_5' &&
                    (<CheckOutHour checkOutHours={pickUpDays}
                                   updateReservationStep={updateReservationStep}
                                   handleReserve={handleReserve}/>)}
                {/*    TODO update flow doorheen reservatie checken --> staytype wordt niet geupdate waardoor prijs niet meer klopt, alles nakijken om finaal te geraken voor focus op backend!*/}
            </Grid>)}
            <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar}/>
        </Grid>
    )
}

export default withAuthenticationRequired(NewReservationPlanner, {
    onRedirecting: () => <Loading/>,
    returnTo: '/nieuwe-reservatie'
});
