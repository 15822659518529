import React, {useEffect, useState} from 'react';
import {Paper, Grid, Typography, Button, Divider, Card, CardContent, Box} from '@mui/material';
import {format, startOfMonth, endOfMonth, addDays, isSameMonth, isToday, addMonths} from 'date-fns';
import {useReservationAdminAPI} from "../../service/useReservationAdminAPI";
import GetErrorSnackbar from "../../common/Snackbar";

const MonthView = ({toggleMonthDayView}) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const {getReservationPerDayForMonthAndYear} = useReservationAdminAPI();
    const [reservationPerDay, setReservationPerDAy] = useState([])

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    function handleFetchError(reason) {
        setSnackbarMessage(reason)
        setOpenSnackbar(true)
    }

    useEffect(() => {
        getReservationPerDayForMonthAndYear(currentDate.getFullYear(), currentDate.getMonth())
            .then(value => setReservationPerDAy(value))
            .catch(() => handleFetchError('Maandoverzicht kon niet opgehaald worden'))
    }, [currentDate]);

    const createClickHandler = (day) => () => toggleMonthDayView(false, day);

    const renderDays = () => {
        const startDate = startOfMonth(currentDate);
        const endDate = endOfMonth(currentDate);

        let days = [];
        let day = startDate;

        while (day <= endDate) {
            const formattedDate = format(day, 'dd');
            days.push(
                <Grid item xs={2} sm={1} key={day.toString()}>
                    <Card variant="outlined">
                        <CardContent>
                            <Button
                                style={{
                                    padding: '10px',
                                    textAlign: 'center',
                                    background: isSameMonth(day, currentDate) ? '#fff' : '#f0f0f0',
                                    color: isToday(day) ? 'red' : 'black',
                                }}
                                onClick={createClickHandler(day)}
                            >
                                <Box>
                                    <Typography variant="h4" component="div">{formattedDate}</Typography>
                                    <Divider />
                                    <Typography variant="caption" component="div">{reservationPerDay[day.getDate() - 1]?.numberOfReservations}</Typography>
                                    <Typography variant="p" component="div">Bookings</Typography>
                                </Box>
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            );
            day = addDays(day, 1);
        }

        return (
            <Grid container spacing={1}>
                {days}
            </Grid>
        );
    };

    return (
        <Paper>
            <Grid container justifyContent="center" sx={{textAlign: 'center', marginLeft: 2}}>
                <Grid item>
                    <Button onClick={() => setCurrentDate(addMonths(currentDate, -1))}>
                        Vorige maand
                    </Button>
                </Grid>
                <Grid item>
                    <Typography variant="h5">{format(currentDate, 'MMMM yyyy')}</Typography>
                </Grid>
                <Grid item>
                    <Button onClick={() => setCurrentDate(addMonths(currentDate, 1))}>
                        Volgende maand
                    </Button>
                </Grid>
            </Grid>
            {renderDays()}
            <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar} />
        </Paper>
    );
};

export default MonthView;
