import React, {createContext, useState} from 'react';
import _ from 'lodash';

// Create a context
export const GlobalStateContext = createContext();

const initialState = {
    reservationMeta: {
        fromDate: undefined,
        toDate: undefined,
        catsInReservationIds: [],
        checkInHour: undefined,
        checkOutHour: undefined,
        stayTypes: []
    },
    reservationEditFeature: false,
    reservationUuid: '',
    reservationDuration: {},
    lastSelectedDateInCalender: new Date() // need because of the calendar will not render if dynamically changed for some reason
};

export const GlobalStateProvider = ({children}) => {
    const [globalState, setGlobalState] = useState(initialState);

    const updateGlobalState = (newState) => {
        setGlobalState(prevState => _.merge({}, prevState, newState));
    };

    const setPartOfGlobalState = (key, value) => {
        setGlobalState(prevState => _.set({ ...prevState }, key, value));
    };

    return (
        <GlobalStateContext.Provider value={{globalState, updateGlobalState, setPartOfGlobalState}}>
            {children}
        </GlobalStateContext.Provider>
    );
};
