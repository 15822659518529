import React, {useEffect, useState} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem, Grid, Typography, IconButton} from "@mui/material";
import Loading from "../common/Loading";
import GetErrorSnackbar from "../../common/Snackbar";
import {useReservationAdminAPI} from "../../service/useReservationAdminAPI";
import {deepClone} from "@mui/x-data-grid/utils/utils";
import DeleteIcon from '@mui/icons-material/Delete';
import {formatDate, formatHour} from "../../common/DateUtil";

const PickUpDeliverySchedules = () => {
    const {getPickUpDeliverSchedules, updatePickUpDeliverSchedules} = useReservationAdminAPI();
    const [schedules, setSchedules] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        getPickUpDeliverSchedules()
            .then(data => {
                setSchedules(data);
            })
            .catch(error => {
                setSnackbarMessage('Error fetching schedules');
                setOpenSnackbar(true);
            })
            .finally(() => setIsLoading(false));
    }, []);

    const handleAddHour = (scheduleId, dayOfWeek, newHour, type) => {

        let deepClone1 = deepClone(schedules);
        deepClone1.forEach(schedule => {
            if (schedule.id === scheduleId) {
                schedule.checkScheduleDays.forEach(day => {
                    if (day.dayOfWeek === dayOfWeek) {
                        day[type] = day[type] ? [...new Set([...day[type], newHour])].sort() : [newHour]
                    }
                })
            }
        })

        setSchedules(deepClone1)
    };

    const handleRemoveHour = (scheduleId, dayOfWeek, hourToRemove, type) => {
        let deepClone1 = deepClone(schedules);
        deepClone1.forEach(schedule => {
            if (schedule.id === scheduleId) {
                schedule.checkScheduleDays.forEach(day => {
                    if (day.dayOfWeek === dayOfWeek) {
                        // Filter out the hour to remove from the day.hours array
                        day[type] = day[type] ? day[type].filter(hour => hour !== hourToRemove) : [];
                    }
                });
            }
        });

        setSchedules(deepClone1);
    };

    const handleUpdate = () => {
        updatePickUpDeliverSchedules(schedules)
            .catch(() => console.log("Something went wrong"))
    }


    return (
        <>
            {isLoading ? <Loading/> : (
                <Grid container spacing={2} style={{padding: '100px'}}>
                    <Button onClick={() => handleUpdate()} variant="contained">
                        Configuratie opslaan
                    </Button>
                    {schedules.map((schedule, index) => (
                        <Grid item xs={12} key={schedule.id}>
                            <Typography variant="h6" style={{marginBottom: '20px'}}>Schedule from {formatDate(schedule.startPeriod)} to {formatDate(schedule.endPeriod)}</Typography>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="schedule table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Day of Week</TableCell>
                                            <TableCell>Add Check-in Hours</TableCell>
                                            <TableCell>Check-in Hours</TableCell>
                                            <TableCell>Add Check-out Hour</TableCell>
                                            <TableCell>Check-out Hours</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {schedule.checkScheduleDays.map(checkScheduleDay => (
                                            <TableRow key={index}>
                                                <TableCell>{checkScheduleDay.dayOfWeek}</TableCell>
                                                <TableCell>
                                                    <Select
                                                        value=""
                                                        displayEmpty
                                                        onChange={(e) => handleAddHour(schedule.id, checkScheduleDay.dayOfWeek, e.target.value, "checkInHours")}
                                                        inputProps={{'aria-label': 'Without label'}}
                                                        style={{marginTop: '10px'}}
                                                    >
                                                        <MenuItem value=""><em>None</em></MenuItem>
                                                        {Array.from({length: 7}, (_, i) => {
                                                            const hour = 16 + Math.floor(i / 2); // Start from 8 and add half of index
                                                            const minute = i % 2 === 0 ? '00' : '30';
                                                            return `${hour.toString().padStart(2, '0')}:${minute}`;
                                                        }).map(option => (
                                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell>
                                                    {checkScheduleDay.checkInHours.map(hour => {
                                                        return <>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'flex-start',
                                                                justifyContent: 'flex-start',
                                                                marginBottom: '8px'
                                                            }}>
                                                                <p>
                                                                    {formatHour(hour)}
                                                                    <IconButton
                                                                        size="small"
                                                                        color="error"
                                                                        onClick={() => handleRemoveHour(schedule.id, checkScheduleDay.dayOfWeek, hour, "checkInHours")}
                                                                    >
                                                                        <DeleteIcon/>
                                                                    </IconButton>
                                                                </p>
                                                            </div>
                                                        </>
                                                    })}
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        value=""
                                                        displayEmpty
                                                        onChange={(e) => handleAddHour(schedule.id, checkScheduleDay.dayOfWeek, e.target.value, "checkOutHours")}
                                                        inputProps={{'aria-label': 'Without label'}}
                                                        style={{marginTop: '10px'}}
                                                    >
                                                        <MenuItem value=""><em>None</em></MenuItem>
                                                        {Array.from({length: 15}, (_, i) => {
                                                            const hour = 8 + Math.floor(i / 2); // Start from 8 and add half of index
                                                            const minute = i % 2 === 0 ? '00' : '30';
                                                            return `${hour.toString().padStart(2, '0')}:${minute}`;
                                                        }).map(option => (
                                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell>
                                                    {checkScheduleDay.checkOutHours.map(hour => {
                                                        return <>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'flex-start',
                                                                justifyContent: 'flex-start',
                                                                marginBottom: '8px'
                                                            }}>
                                                                <p>
                                                                    {formatHour(hour)}
                                                                    <IconButton
                                                                        size="small"
                                                                        color="error"
                                                                        onClick={() => handleRemoveHour(schedule.id, checkScheduleDay.dayOfWeek, hour, "checkOutHours")}
                                                                    >
                                                                        <DeleteIcon/>
                                                                    </IconButton>
                                                                </p>
                                                            </div>
                                                        </>
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    ))}
                    <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar}/>
                </Grid>
            )}
        </>
    );
};

export default withAuthenticationRequired(PickUpDeliverySchedules, {
    onRedirecting: () => <Loading/>,
    returnTo: '/login'
});
