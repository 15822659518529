import {useAuth0} from "@auth0/auth0-react";

export function useCatAPI() {
    const {getAccessTokenSilently} = useAuth0();

    const getCats = async () => {
        try {
            const accessToken = await getAccessTokenSilently();

            const response = await fetch(`${process.env.REACT_APP_BACK_API}/cat`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            // await delay(250);
            // loginWithRedirect();
        }
    };

    const getCatByUuid = async (uuid) => {
        try {
            const accessToken = await getAccessTokenSilently();

            const response = await fetch(`${process.env.REACT_APP_BACK_API}/cat/${uuid}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching personal information:', error);
            throw error;
        }
    };

    const createOrUpdateCat = async (cat, reservationUuid) => {
        if (cat.id) {
            return updateCat(cat)
        } else {
            return createCat(cat, reservationUuid)
        }
    }

    const createCat = async (cat, reservationUuid) => {
        try {
            const accessToken = await getAccessTokenSilently();

            const reservationPath = reservationUuid !== undefined ? '/' + reservationUuid : '';
            const response = await fetch(`${process.env.REACT_APP_BACK_API}/cat${reservationPath}`, {
                method: 'POST',
                headers: {
                    "Content-type": 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(cat)
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            // console.error('Error fetching personal information:', error);
            throw error;
        }
    };

    const updateCat = async (cat) => {
        try {
            const accessToken = await getAccessTokenSilently();

            const response = await fetch(`${process.env.REACT_APP_BACK_API}/cat`, {
                method: 'PUT',
                headers: {
                    "Content-type": 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(cat)
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching personal information:', error);
            throw error;
        }
    };

    return {getCats, createCat, updateCat, createOrUpdateCat, getCatByUuid};
}
