import {withAuthenticationRequired} from "@auth0/auth0-react";
import React, {useEffect, useState} from "react";
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {
    Box,
    Button,
    Card, CardActions, CardContent, CardHeader,
    Grid, MenuItem, Modal, Select, Typography,
} from "@mui/material";
import {useReservationAdminAPI} from "../../service/useReservationAdminAPI";
import Loading from "../common/Loading";
import {useNavigate} from "react-router-dom";
import GetErrorSnackbar from "../../common/Snackbar";
import AssignmentIcon from '@mui/icons-material/Assignment';
import UpdateIcon from '@mui/icons-material/Update';
import DoneIcon from '@mui/icons-material/Done';
import {formatDateTime, parseFormattedDate} from "../../common/DateUtil";

const NewReservationsOverview = () => {
    const {getNewReservations, validateReservation, downloadContracts, updateReservation} = useReservationAdminAPI();
    const [reservations, setReservations] = useState([])
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const handleCloseEditModal = () => setOpenEditModal(false);
    const [currentUuid, setCurrentUuid] = useState(undefined);

    function handleFetchError(reason) {
        setSnackbarMessage(reason)
        setOpenSnackbar(true)
    }

    function fetch() {
        getNewReservations()
            .then(value => setReservations(value))
            .catch(() => handleFetchError('Nieuwe reservaties kunnen niet opgehaald worden'))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetch();
    }, []);

    const handleOpenEditModal = (uuid) => {
        setCurrentUuid(uuid)
        setOpenEditModal(true);
    }

    const renderActionButtons = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{marginRight: 16}}
                    onClick={(event) => {
                        event.stopPropagation(); // don't select this row after clicking
                        handleOpenEditModal(params.row.uuid);
                    }}
                >
                    <UpdateIcon/>
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{marginRight: 16}}
                    onClick={(event) => {
                        event.stopPropagation(); // don't select this row after clicking
                        downloadContracts(params.row.uuid)
                            .then(() => fetch())
                            .catch(() => handleFetchError('Contracten downloaden mislukt'))
                    }}
                >
                    <AssignmentIcon/>
                </Button>
                <Button
                    variant="contained"
                    color="inherit"
                    size="small"
                    style={{marginRight: 16}}
                    onClick={(event) => {
                        event.stopPropagation(); // don't select this row after clicking
                        validateReservation(params.row.uuid)
                            .then(() => fetch())
                            .catch(() => handleFetchError('Valideren van de nieuwe reservatie mislukt'))
                    }}
                >
                    <DoneIcon/>
                </Button>
            </strong>
        )
    }

    const navigateWithUuidTo = (cat, path) => {
        navigate(`/${path}?uuid=${cat.uuid}`);
    }

    const handleUpdateHours = () => {
        updateReservation(reservations.find(value => value.uuid === currentUuid))
            .then(value => {
                handleCloseEditModal();
                fetch();
            })
    }

    const handleSelectChange = (event, field) => {
        const updatedReservations = reservations.map(reservation => {
            if (reservation.uuid === currentUuid) {
                return {...reservation, [field]: event.target.value};
            }
            return reservation;
        });
        setReservations(updatedReservations);
    };

    const columns: GridColDef[] = [
        {
            field: 'checkIn',
            headerName: 'Check-in',
            width: 170,
            valueGetter: (params) => {
                const fromDate = params.row.fromDate;
                const checkInHour = params.row.checkIn;
                return formatDateTime(fromDate, checkInHour);
            },
            sortComparator: (value1, value2) => {
                const date1 = parseFormattedDate(value1);
                const date2 = parseFormattedDate(value2);

                if (date1 < date2) return -1;  // Ascending order
                if (date1 > date2) return 1;   // Descending order
                return 0;
            },
        },
        {
            field: 'checkOut',
            headerName: 'Check-out',
            width: 170,
            valueGetter: (params) => {
                const fromDate = params.row.toDate;
                const checkOut = params.row.checkOut;
                return formatDateTime(fromDate, checkOut);
            },
            sortComparator: (value1, value2) => {
                console.log(`Sorting checkOut values: ${value1}, ${value2}`)
                const date1 = parseFormattedDate(value1);
                const date2 = parseFormattedDate(value2);

                if (date1 < date2) return -1;  // Ascending order
                if (date1 > date2) return 1;   // Descending order
                return 0;
            }
        },
        {
            field: 'verblijven',
            headerName: 'Verblijven',
            width: 100,
            valueGetter: (params) => {
                return `${params.row.stayTypes.map(stay => stay).join(', ')}`;
            }
        },
        {
            field: 'price',
            headerName: 'Prijs',
            width: 100,
            valueGetter: (params) => {
                return `${params.row.price} €`;
            }
        }, {
            field: 'advance',
            headerName: 'Voorschot *',
            width: 100,
            valueGetter: (params) => {
                return `${params.row.advance} €`;
            }
        },
        {
            field: 'aantal',
            headerName: 'Kat(ten)',
            width: 100,
            valueGetter: (params) => {
                return `${params.row.cats.map(cat => cat.name).join(', ')}`;
            },
            renderCell: (params) => {
                const cats = params.row.cats;
                return (
                    <span>
                {cats.map(cat => (
                    <a key={cat.id} onClick={() => navigateWithUuidTo(cat, 'mijn-katten')} style={{textDecoration: 'none', color: 'blue', marginRight: '5px'}}>
                        {cat.name}
                    </a>
                ))}
            </span>
                );
            },
        },
        {
            field: 'eigenaar',
            headerName: 'Eigenaar',
            width: 150,
            valueGetter: (params) => {
                return `${params.row.personInformation.name}`;
            },
            renderCell: (params) => {
                const name = params.value;
                const personInformation = params.row.personInformation; // Assuming you have an ID to link to
                return (
                    <a onClick={() => navigateWithUuidTo(personInformation, 'mijn-profiel')} style={{textDecoration: 'none', color: 'blue'}}>
                        {name}
                    </a>
                );
            },
        },
        {
            field: 'dierenarts', // The field name can be arbitrary since we're using valueGetter
            headerName: 'Dierenarts',
            width: 150,
            valueGetter: (params) => {
                return `${params.row.veterinaryDto.name}`;
            },
            renderCell: (params) => {
                const name = params.value;
                const veterinary = params.row.veterinaryDto; // Assuming you have an ID to link to
                return (
                    <a onClick={() => navigateWithUuidTo(veterinary, 'mijn-dierenarts')} style={{textDecoration: 'none', color: 'blue'}}>
                        {name}
                    </a>
                );
            },
        },
        {
            field: "action",
            headerName: "Acties",
            sortable: false,
            width: 300,
            renderCell: renderActionButtons
        },
    ];

    function getRowId(row) {
        return row.bookingNumber;
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            {isLoading && (<Loading/>)}
            {!isLoading && (
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 50}}>
                    <Card variant="outlined" style={{marginBottom: '10px'}}>
                        <DataGrid
                            rows={reservations}
                            getRowId={getRowId}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {page: 0, pageSize: 20},
                                },
                            }}
                            pageSizeOptions={[10, 10]}
                            // checkboxSelection
                        />
                    </Card>
                    <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar}/>
                    <Modal
                        open={openEditModal}
                        onClose={handleCloseEditModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Card sx={style}>
                            <CardHeader title={<Typography color="secondary" variant="h5">U staat op het punt reservatie aan te passen.</Typography>}/>
                            <CardContent style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Box>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Check-in
                                    </Typography>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                    </Typography>
                                    <Select
                                        value={reservations.find(value => value.uuid === currentUuid)?.checkIn || ""}
                                        displayEmpty
                                        inputProps={{'aria-label': 'Without label'}}
                                        style={{marginTop: '10px'}}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,  // Adjust the height as needed
                                                },
                                            },
                                        }}
                                        onChange={(event) => handleSelectChange(event, 'checkIn')} // Add onChange handler
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {Array.from({length: 38}, (_, i) => {
                                            const hour = 5 + Math.floor(i / 2);
                                            const minute = i % 2 === 0 ? '00' : '30';
                                            const seconds = '00';
                                            return `${hour.toString().padStart(2, '0')}:${minute}:${seconds}`;
                                        }).map(option => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Check-out
                                    </Typography>
                                    <Select
                                        value={reservations.find(value => value.uuid === currentUuid)?.checkOut || ""}
                                        displayEmpty
                                        inputProps={{'aria-label': 'Without label'}}
                                        style={{marginTop: '10px'}}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,  // Adjust the height as needed
                                                },
                                            },
                                        }}
                                        onChange={(event) => handleSelectChange(event, 'checkOut')} // Add onChange handler
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {Array.from({length: 38}, (_, i) => {
                                            const hour = 5 + Math.floor(i / 2);
                                            const minute = i % 2 === 0 ? '00' : '30';
                                            const seconds = '00';
                                            return `${hour.toString().padStart(2, '0')}:${minute}:${seconds}`;
                                        }).map(option => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            </CardContent>
                            <CardActions style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Button onClick={handleCloseEditModal}>Annuleren</Button>
                                <Button onClick={handleUpdateHours}>Opslaan</Button>
                            </CardActions>
                        </Card>
                    </Modal>
                </Grid>)}
        </>
    )
};

export default withAuthenticationRequired(NewReservationsOverview, {
    onRedirecting: () => <Loading/>,
    returnTo: '/login'
});
