import React, {useContext, useEffect, useState} from 'react';
import {TextField, Button, Typography, Box, Grid, Card, CardContent, CardActions, InputLabel, FormControl, Select, MenuItem} from '@mui/material';
import {GlobalStateContext} from "../../state/GlobalStateProvider";
import {usePersonInformationAPI} from "../../service/usePersonInformationAPI";
import {useLocation, useSearchParams} from "react-router-dom";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../common/Loading";
import GetErrorSnackbar from "../../common/Snackbar";

const PersonForm = ({updateCurrentStep, providedPerson}) => {
    const {user} = useAuth0();
    const [isLoading, setIsLoading] = useState(false);
    const {getPersonInformation, updatePersonInformation, getPersonInformationByUuid} = usePersonInformationAPI();
    const location = useLocation();
    const {globalState} = useContext(GlobalStateContext);
    const [errors, setErrors] = useState({});
    const [personalInfo, setPersonalInfo] = useState({
        name: '', forename: '', street: '', number: '', bus: '', postalcode: '', municipality: '', country: '', gsm: '', email: '',
    });

    const countries = ["België", "Frankrijk", "Luxemburg", "Nederland"];

    const [searchParams] = useSearchParams();
    const [readOnly, setReadOnly] = useState(false);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        if (searchParams.get('uuid')) {
            setIsLoading(true)
            setReadOnly(true)
            getPersonInformationByUuid(searchParams.get('uuid'))
                .then(personInfo => setPersonalInfo(personInfo))
                .catch(() => handleFetchError('Persoon data ophalen mislukt'))
                .finally(() => setIsLoading(false));
        } else if (providedPerson) {
            setPersonalInfo(providedPerson);
        } else {
            setIsLoading(true)
            getPersonInformation()
                .then(personInfo => setPersonalInfo(personInfo))
                .catch(() => handleFetchError('Persoon data ophalen mislukt'))
                .finally(() => setIsLoading(false));
        }
    }, []);


    function handleFetchError(reason) {
        setSnackbarMessage(reason)
        setOpenSnackbar(true)
    }

    const validateForm = () => {
        let tempErrors = {};
        tempErrors.name = personalInfo.name ? "" : "Naam is een verplicht veld.";
        tempErrors.forename = personalInfo.forename ? "" : "Voornaam is een verplicht veld.";
        tempErrors.street = personalInfo.street ? "" : "Straat is een verplicht veld.";
        tempErrors.number = personalInfo.number ? "" : "Huisnummer is een verplicht veld.";
        tempErrors.postalcode = personalInfo.postalcode ? "" : "Postcode is een verplicht veld.";
        tempErrors.municipality = personalInfo.municipality ? "" : "Gemeente is een verplicht veld.";
        tempErrors.country = personalInfo.country ? "" : "Land is een verplicht veld.";
        tempErrors.gsm = personalInfo.gsm ? "" : "Gsm is een verplicht veld.";
        tempErrors.email = personalInfo.email ? "" : "E-mail is een verplicht veld..";

        if (personalInfo.email) {
            let currentEmails = personalInfo.email
                .split(',')
                .filter((e) => e && e.trim());
            let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
            for (let i = 0; i < currentEmails.length; i++) {
                if (!regex.test(currentEmails[i].replace(/\s/g, ''))) {
                    tempErrors.email = "E-mail niet geldig.";
                }
            }
        }

        setErrors({...tempErrors});
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleChange = (event) => {
        const {name, value, type, checked} = event.target;
        setPersonalInfo(prevState => ({
            ...prevState, [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            setIsLoading(true)
            updatePersonInformation(personalInfo, globalState.reservationUuid)
                .then(() => updateCurrentStep(4))
                .catch(() => handleFetchError('Updaten persoon data mislukt'))
                .finally(() => setIsLoading(false));
        }
    };

    function goBack() {
        updateCurrentStep(2)
    }

    function updatePerson() {
        if (validateForm()) {
            setIsLoading(true)
            updatePersonInformation(personalInfo)
                .then(() => {})
                .catch(() => handleFetchError('Updaten persoon data mislukt'))
                .finally(() => setIsLoading(false));
        }
    }

    return (
        <>
            {isLoading && (<Loading/>)}
            {!isLoading && (
                <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{textAlign: 'center'}}>
                    <Grid item xs={12} sm={10} md={8} lg={6}>
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="h5" color="secondary">
                                        Persoonlijke informatie
                                    </Typography>
                                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                                        <TextField margin="normal" required fullWidth id="owner-name" label="Naam" name="name" autoFocus value={personalInfo.name}
                                                   onChange={handleChange}
                                                   error={!!errors.name} helperText={errors.name} disabled={readOnly}/>
                                        <TextField margin="normal" required fullWidth id="owner-forename" label="Voornaam" name="forename" value={personalInfo.forename}
                                                   onChange={handleChange}
                                                   error={!!errors.forename} helperText={errors.forename} disabled={readOnly}/>
                                        <TextField margin="normal" required fullWidth id="contact-street" label="Straat" name="street" value={personalInfo.street}
                                                   onChange={handleChange}
                                                   error={!!errors.street} helperText={errors.street} disabled={readOnly}/>
                                        <TextField margin="normal" required fullWidth id="contact-number" type="number" label="Huisnummer" name="number" value={personalInfo.number}
                                                   onChange={handleChange} error={!!errors.number} helperText={errors.number} disabled={readOnly}/>
                                        <TextField margin="normal" fullWidth id="contact-bus" label="Bus" name="bus" value={personalInfo.bus} onChange={handleChange}
                                                   disabled={readOnly}/>
                                        <TextField margin="normal" required fullWidth id="contact-postalcode" label="Postcode" name="postalcode" value={personalInfo.postalcode}
                                                   onChange={handleChange} error={!!errors.postalcode} helperText={errors.postalcode} disabled={readOnly}/>
                                        <TextField margin="normal" required fullWidth id="contact-municipality" label="Gemeente" name="municipality"
                                                   value={personalInfo.municipality}
                                                   onChange={handleChange} error={!!errors.municipality} helperText={errors.municipality} disabled={readOnly}/>

                                        <FormControl fullWidth>
                                            <InputLabel id="country-select-label">Land</InputLabel>
                                            <Select
                                                align="left"
                                                labelId="country-select-label"
                                                id="country-select"
                                                name="country"
                                                value={personalInfo.country}
                                                onChange={handleChange}
                                                label="Country"
                                                disabled={readOnly}
                                            >
                                                {countries.map((country) => (
                                                    <MenuItem value={country} key={country}>{country}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField margin="normal" required fullWidth id="contact-gsm" label="GSM" name="gsm" value={personalInfo.gsm} onChange={handleChange}
                                                   error={!!errors.gsm}
                                                   helperText={errors.gsm}
                                                   disabled={readOnly}/>
                                        <TextField required margin="normal" fullWidth id="contact-email" label="E-mail" name="email" value={personalInfo.email}
                                                   onChange={handleChange}
                                                   error={!!errors.email} helperText={errors.email} disabled={readOnly}/>

                                    </Box>
                                </CardContent>
                                <CardActions style={{
                                    display: 'flex', justifyContent: location.pathname !== "/mijn-profiel" ? 'space-between' : 'right'
                                }}>
                                    {location.pathname !== "/mijn-profiel" && (
                                        <>
                                            <Button onClick={goBack} variant="contained" disabled={isLoading}>
                                                Vorige
                                            </Button>
                                            <Button type="submit" variant="contained" disabled={isLoading}>
                                                Volgende
                                            </Button>
                                        </>
                                    )}
                                    {!readOnly && user.email && location.pathname === "/mijn-profiel" && (
                                        <Button onClick={updatePerson} variant="contained" disabled={isLoading}>
                                            Update
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        </Box>
                    </Grid>
                    <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar}/>
                </Grid>)}
        </>
    )
};

export default withAuthenticationRequired(PersonForm, {
    onRedirecting: () => <Loading/>,
    returnTo: '/nieuwe-reservatie'
});
