import {useAuth0} from "@auth0/auth0-react";

export function usePersonInformationAPI() {
    const { getAccessTokenSilently } = useAuth0();

    const getPersonInformation = async () => {
        try {
            const accessToken = await getAccessTokenSilently();

            const response = await fetch(`${process.env.REACT_APP_BACK_API}/personal-information`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching personal information:', error);
            throw error;
        }
    };

    const getPersonInformationByUuid = async (uuid) => {
        try {
            const accessToken = await getAccessTokenSilently();

            const response = await fetch(`${process.env.REACT_APP_BACK_API}/personal-information/${uuid}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching personal information:', error);
            throw error;
        }
    };

    const updatePersonInformation = async (personUpdate, reservationUUID) => {
        try {
            const accessToken = await getAccessTokenSilently();

            const response = await fetch(`${process.env.REACT_APP_BACK_API}/personal-information${reservationUUID ? '/' + reservationUUID : ''}`, {
                method: 'PUT',
                headers: {
                    "Content-type": 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(personUpdate)
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching personal information:', error);
            throw error;
        }
    };

    const getEmergencyContactInformation = async () => {
        try {
            const accessToken = await getAccessTokenSilently();

            const response = await fetch(`${process.env.REACT_APP_BACK_API}/emergency-contact`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching personal information:', error);
            throw error;
        }
    };

    const getEmergencyContactInformationByUuid = async (uuid) => {
        try {
            const accessToken = await getAccessTokenSilently();

            const response = await fetch(`${process.env.REACT_APP_BACK_API}/emergency-contact/${uuid}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching personal information:', error);
            throw error;
        }
    };

    const updateEmergencyContactInformation = async (personUpdate, reservationUUID) => {
        try {
            const accessToken = await getAccessTokenSilently();

            const response = await fetch(`${process.env.REACT_APP_BACK_API}/emergency-contact${reservationUUID ? '/' + reservationUUID : ''}`, {
                method: 'PUT',
                headers: {
                    "Content-type": 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(personUpdate)
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching personal information:', error);
            throw error;
        }
    };

    return {
        getPersonInformation,
        updatePersonInformation,
        getPersonInformationByUuid,
        getEmergencyContactInformation,
        updateEmergencyContactInformation,
        getEmergencyContactInformationByUuid
    };
}
