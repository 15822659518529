import {withAuthenticationRequired} from "@auth0/auth0-react";
import React, {useContext, useEffect, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Snackbar,
    Typography,
    Divider,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody, Checkbox
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useReservationAdminAPI} from "../../service/useReservationAdminAPI";
import MonthView from "./MonthView";
import DayView from "./Dayview";
import Loading from "../common/Loading";
import GetErrorSnackbar from "../../common/Snackbar";

const MyReservations = () => {
    const navigate = useNavigate();
    const {getAllReservationForMonthAndYear} = useReservationAdminAPI();
    const [reservations, setReservations] = useState([])
    const [daySelected, setDaySelected] = useState(undefined);
    const [showMonth, setShowMonth] = useState(true)

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    function handleFetchError(reason) {
        setSnackbarMessage(reason)
        setOpenSnackbar(true)
    }

    useEffect(() => {
        handleRefreshScreen();
    }, []);


    const toggleMonthDayView = (showMonth, date) => {
        setShowMonth(showMonth);
        if (date !== undefined) {
            setDaySelected(date);
        }
    };

    const handleRefreshScreen = () => {
        setIsLoading(true);
        getAllReservationForMonthAndYear(1, 2024)
            .then(value => setReservations(value))
            .catch(reason => handleFetchError('Ophalen van reservaties in overzicht mislukt'))
            .finally(() => setIsLoading(false));
    }

    return (
        <>
            {isLoading && (<Loading/>)}
            {!isLoading && (
                <Grid container justifyContent="center" sx={{textAlign: 'center', marginLeft: 5}}>
                    <Grid item xs={12}>
                        {(<MonthView toggleMonthDayView={toggleMonthDayView}/>)}
                        {(<DayView selectedDay={daySelected} refreshScreen={handleRefreshScreen}/>)}
                    </Grid>
                    <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar}/>
                </Grid>)}
        </>
    )
};

export default withAuthenticationRequired(MyReservations, {
    onRedirecting: () => <Loading/>,
    returnTo: '/nieuwe-reservatie'
});
