import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../../common/Loading";
import React, {useState} from "react";
import {Button, Card, CardActions, CardContent, CardHeader, Grid, Typography} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import {useReservationAPI} from "../../../service/useReservationAPI";
import GetErrorSnackbar from "../../../common/Snackbar";

function ReservationFailed() {
    const [searchParams] = useSearchParams();
    const {getPaymentLink} = useReservationAPI();

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    function handleFetchError(reason) {
        setSnackbarMessage(reason)
        setOpenSnackbar(true)
    }


    const redoPayment = () => {
        getPaymentLink(searchParams.get('reservation_id'))
            .then(value => {
                window.location.href = value;
            })
            .catch(() => handleFetchError('Opnieuw uitvoeren van de betaling mislukt, contacteer Catland'));
    }

    return (
        <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{textAlign: 'center'}}>
            <Grid item xs={12}>
                <Card variant="outlined">
                    <CardHeader title={<Typography color="secondary" variant="h5">Betaling niet geslaagd!</Typography>}/>
                    <CardContent>
                        Probeer opnieuw
                    </CardContent>
                    <CardActions style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button onClick={redoPayment} variant="contained">
                            Voor betaling opnieuw uit
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar} />
        </Grid>
    )
}

export default withAuthenticationRequired(ReservationFailed, {
    onRedirecting: () => <Loading/>,
    returnTo: '/nieuwe-reservatie'
});
