import React, {useContext, useEffect, useState} from 'react';
import {
    TextField,
    Button,
    Typography,
    Box,
    FormControlLabel,
    Checkbox,
    FormControl,
    Grid,
    Card,
    CardContent,
    CardActions, InputLabel, Select, MenuItem, Alert, Tooltip,
} from '@mui/material';
import {GlobalStateContext} from "../../state/GlobalStateProvider";
import {useCatAPI} from "../../service/useCatAPI";
import {useLocation, useSearchParams} from "react-router-dom";
import PetsIcon from "@mui/icons-material/Pets";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../common/Loading";
import {scrollToElementId} from "../../utils/ScreenUtil";
import GetErrorSnackbar from "../../common/Snackbar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const catModel = {
    name: '',
    age: '',
    breed: '',
    sex: '',
    color: '',
    chipNumber: '',
    spayedNeutered: false,
    ontvlooitEnOntwormt: false,
    kattenziekte: false,
    niesziekte: false,
    kattenleukemie: false,
    medicalHistory: '',
    diet: '',
    behaviour: '',
    associationAnimals: '',
    contactOtherAnimals: false
}

const CatInformationForm = ({updateCurrentStep, providedCat}) => {
    const {getCats, updateCat, getCatByUuid} = useCatAPI();
    const location = useLocation();
    const {globalState, updateGlobalState} = useContext(GlobalStateContext);
    const [currentCat, setCurrentCat] = useState(catModel);
    const [cats, setCats] = useState([]);
    const [errors, setErrors] = useState({});
    const [currentIndex, setCurrentIndex] = useState(0);
    const [searchParams] = useSearchParams();
    const [readOnly, setReadOnly] = useState(false);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    function handleFetchError(reason) {
        setSnackbarMessage(reason)
        setOpenSnackbar(true)
    }

    useEffect(() => {
        if (providedCat) {
            setCurrentCat(providedCat)
        } else {
            setIsLoading(true);
            if (location.pathname !== "/mijn-katten") {
                updateGlobalState({catsForReservation: []})
            }

            if (searchParams.get('uuid')) {
                setReadOnly(true)
                getCatByUuid(searchParams.get('uuid'))
                    .then(value => {
                        if (value != null) {
                            setCurrentCat(value)
                            // setCurrentIndex(0)
                            // setCats(value)
                        } else {
                            throw {error: 'Geen katten gevonden'}; // should never happen
                        }
                    })
                    .catch(() => handleFetchError('Kat ophalen mislukt'))
                    .finally(() => setIsLoading(false));
            } else if (globalState.reservationMeta.catsInReservationIds.length === 0) {
                setIsLoading(true)

                getCats()
                    .then(value => {
                        if (value.length > 0) {
                            setCurrentCat(value[0])
                            setCurrentIndex(0)
                            setCats(value)
                        }
                    })
                    .catch(() => handleFetchError('Mijn katten ophalen mislukt'))
                    .finally(() => setIsLoading(false));
            } else {
                getCats()
                    .then(value => {
                        if (value.length > 0) {
                            const catsForReservation = value.filter(value => globalState.reservationMeta.catsInReservationIds.includes(value.id));
                            setCats(catsForReservation);
                            setCurrentCat(catsForReservation[0])
                            setCurrentIndex(0)
                        } else {
                            throw {error: 'Geen katten gevonden'}; // should never happen
                        }
                    })
                    .catch(() => handleFetchError('Mijn katten ophalen mislukt'))
                    .finally(() => setIsLoading(false));
            }
        }
    }, []);

    const validateForm = () => {
        let tempErrors = {};
        tempErrors.name = currentCat.name ? "" : "Naam is een verplicht veld.";
        tempErrors.age = currentCat.age ? "" : "Leeftijd is een verplicht veld.";
        tempErrors.breed = currentCat.breed ? "" : "Ras is een verplicht veld.";
        tempErrors.sex = currentCat.sex ? "" : "Geslacht is een verplicht veld.";
        tempErrors.color = currentCat.color ? "" : "Kleur is een verplicht veld.";
        tempErrors.behaviour = currentCat.behaviour ? "" : "Gedrag thuis is een verplicht veld.";
        tempErrors.associationAnimals = currentCat.associationAnimals ? "" : "Omgang met andere dieren is een verplicht veld.";

        if (currentCat.chipNumber) {
            const chipNumberPattern = /^[0-9]{15}$/; // Example pattern for a 15-digit chip number
            tempErrors.chipNumber = chipNumberPattern.test(currentCat.chipNumber) ? "" : "Chipnummer niet geldig, een chipnummer heeft 15 cijfers.";
        }

        setErrors({...tempErrors});
        return Object.entries(tempErrors)
            .filter(([, value]) => value !== '')
            .map(([key]) => key);
    };

    const handleChange = (event) => {
        const {name, value, type, checked} = event.target;
        setCurrentCat(prevState => ({
            ...prevState, [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleCatOperation = successCallback => {
        setIsLoading(true)
        const invalidNameArray = validateForm();
        if (invalidNameArray.length === 0) {
            updateCat(currentCat)
                .then(value => {
                    successCallback(value);
                    setState({...state, open: true});
                })
                .catch(() => handleFetchError('Mijn katten updaten mislukt'))
                .finally(() => setIsLoading(false));
        } else {
            scrollToElementId(invalidNameArray[0]);
            setIsLoading(false)
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleCatOperation(() => updateCurrentStep(6));
    };

    const updateCurrentCat = () => handleCatOperation(value => setCurrentCat(value));

    const changeCat = index => {
        handleCatOperation(() => {
            setCurrentCat(cats[index]);
            setCurrentIndex(currentIndex + 1); // TODO always increment looks wrong, get the index via the button
        });
    };

    const goBack = () => updateCurrentStep(4);

    const styles = {
        optionContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: "center",
            alignItems: 'center',
            gap: '20px',
            marginBottom: '20px'
        },
        optionItem: {
            display: 'flex',
            alignItems: 'center',
        },
    };

    const [state, setState] = React.useState({open: false, vertical: 'top', horizontal: 'center'});

    return (
        <>
            {isLoading && (<Loading/>)}
            {!isLoading && (
                <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{textAlign: 'center'}}>
                    <Grid item xs={12} sm={10} md={8} lg={6}>
                        <Typography variant="h5" color="secondary" gutterBottom>
                            Geef de naam in van je kat
                            <div style={styles.optionContainer}>
                                {cats.map((cat, index) => (
                                    <div key={index} style={styles.optionItem}>
                                        <Button color="primary" onClick={() => changeCat(index)} disabled={isLoading}>
                                            <PetsIcon/> {cat.name}
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </Typography>

                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                            <Card variant="outlined" style={{marginBottom: '10px'}}>
                                <CardContent>
                                    <Typography variant="h5" color="secondary">
                                        Gegevens over {currentCat.name}
                                    </Typography>
                                    <TextField disabled margin="normal" fullWidth id="name" label="Naam" name="name" value={currentCat.name} onChange={handleChange}
                                               error={!!errors.name}
                                               helperText={errors.name}/>
                                    <TextField autoFocus margin="normal" required fullWidth id="age" label="Leeftijd" name="age" type="number" value={currentCat.age || ''}
                                               onChange={handleChange}
                                               disabled={readOnly}
                                               error={!!errors.age}
                                               helperText={errors.age}/>
                                    <TextField margin="normal" required fullWidth id="breed" label="Ras" name="breed" value={currentCat.breed || ''} onChange={handleChange}
                                               error={!!errors.breed}
                                               disabled={readOnly}
                                               helperText={errors.breed}/>

                                    <FormControl fullWidth>
                                        <InputLabel id="sex-select-label">Geslacht *</InputLabel>
                                        <Select
                                            required
                                            align="left"
                                            labelId="sex-select-label"
                                            id="sex-select"
                                            name="sex"
                                            disabled={readOnly}
                                            value={currentCat.sex}
                                            onChange={handleChange}
                                            label="Geslacht"
                                        >
                                            <MenuItem value='Male'>Mannelijk</MenuItem>
                                            <MenuItem value='Female'>Vrouwelijk</MenuItem>
                                            <MenuItem value='Onbekend'>Onbekend</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField margin="normal" required fullWidth id="color" label="Kleur" name="color" value={currentCat.color || ''} onChange={handleChange}
                                               disabled={readOnly}
                                               error={!!errors.color}
                                               helperText={errors.color}/>
                                    <TextField margin="normal" fullWidth id="chipNumber" label="Chipnummer" name="chipNumber" value={currentCat.chipNumber || ''} onChange={handleChange}
                                               disabled={readOnly}
                                               error={!!errors.chipNumber}
                                               helperText={errors.chipNumber}/>
                                </CardContent>
                            </Card>
                            <Card variant="outlined" style={{marginBottom: '10px'}}>
                                <CardContent>
                                    <Typography component="h1" variant="h5">
                                        Medische informatie van {currentCat.name}
                                    </Typography>
                                    <FormControl component="fieldset" variant="standard" fullWidth margin="normal">
                                        <Tooltip title="sterilisatie of castratie is verplicht voor een verblijf bij kattenhotel Catland">
                                            <Box display="inline-flex" alignItems="center">
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={currentCat.spayedNeutered}
                                                        onChange={handleChange}
                                                        disabled={readOnly}
                                                        name="spayedNeutered"
                                                        color="primary"
                                                    />}
                                                    label="castratie/sterilisatie"
                                                />
                                                <InfoOutlinedIcon sx={{height: 15, marginLeft: -2}}/>
                                            </Box>
                                        </Tooltip>
                                    </FormControl>
                                    <FormControl component="fieldset" variant="standard" fullWidth margin="normal">
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={currentCat.ontvlooitEnOntwormt}
                                                onChange={handleChange}
                                                disabled={readOnly}
                                                name="ontvlooitEnOntwormt"
                                                color="primary"
                                            />}
                                            label="ontvlooit & ontwormt"
                                        />
                                    </FormControl>
                                    <FormControl component="fieldset" variant="standard" fullWidth margin="normal">
                                        <Tooltip title="verplichte vaccinatie voor een verblijf bij kattenhotel Catland">
                                            <Box display="inline-flex" alignItems="center">
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={currentCat.kattenleukemie}
                                                        onChange={handleChange}
                                                        disabled={readOnly}
                                                        name="kattenleukemie"
                                                        color="primary"
                                                    />}
                                                    label="kattenleukemie (leucose)"
                                                />
                                                <InfoOutlinedIcon sx={{height: 15, marginLeft: -2}}/>
                                            </Box>
                                        </Tooltip>
                                    </FormControl>
                                    <FormControl component="fieldset" variant="standard" fullWidth margin="normal">
                                        <Tooltip title="verplichte vaccinatie voor een verblijf bij kattenhotel Catland">
                                            <Box display="inline-flex" alignItems="center">
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={currentCat.kattenziekte}
                                                        onChange={handleChange}
                                                        disabled={readOnly}
                                                        name="kattenziekte"
                                                        color="primary"
                                                    />}
                                                    label="kattenziekte (panleucopenie / typhus)"
                                                />
                                                <InfoOutlinedIcon sx={{height: 15, marginLeft: -2}}/>
                                            </Box>
                                        </Tooltip>
                                    </FormControl>
                                    <FormControl component="fieldset" variant="standard" fullWidth margin="normal">
                                        <Tooltip title="verplichte vaccinatie voor een verblijf bij kattenhotel Catland">
                                            <Box display="inline-flex" alignItems="center">
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={currentCat.niesziekte}
                                                        onChange={handleChange}
                                                        disabled={readOnly}
                                                        name="niesziekte"
                                                        color="primary"
                                                    />}
                                                    label="niesziekte (rhinotracheïtis / coryza)"
                                                />
                                                <InfoOutlinedIcon sx={{height: 15, marginLeft: -2}}/>
                                            </Box>
                                        </Tooltip>
                                    </FormControl>

                                    <a href="https://kattenhotel-catland.be/vaccinaties-controleren.html" target="_blank" style={{float:"left", marginBottom: '25px'}}>
                                        Hoe controleer ik de vaccinaties van mijn kat
                                    </a>
                                    {/* Health Information */}
                                    <TextField margin="normal" fullWidth id="diet" label="Dieet" name="diet" multiline rows={4} disabled={readOnly}
                                               value={currentCat.diet || ''} onChange={handleChange} error={!!errors.diet} helperText={errors.diet}/>
                                    <TextField margin="normal" fullWidth id="medicalHistory" label="Medicatie" name="medicalHistory" multiline rows={4} disabled={readOnly}
                                               value={currentCat.medicalHistory || ''}
                                               onChange={handleChange} error={!!errors.medicalHistory} helperText={errors.medicalHistory}/>
                                </CardContent>
                            </Card>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography component="h1" variant="h5">
                                        Gedrag van {currentCat.name}
                                    </Typography>
                                    <TextField inputProps={{ maxLength: 300 }} margin="normal" required fullWidth id="behaviour" label="Gedrag thuis" name="behaviour" multiline rows={4} disabled={readOnly}
                                               value={currentCat.behaviour || ''} onChange={handleChange} error={!!errors.behaviour} helperText={errors.behaviour}/>
                                    <TextField inputProps={{ maxLength: 300 }} margin="normal" required fullWidth id="associationAnimals" label="Omgang met andere dieren" name="associationAnimals" multiline
                                               rows={4} disabled={readOnly}
                                               value={currentCat.associationAnimals || ''} onChange={handleChange} error={!!errors.associationAnimals}
                                               helperText={errors.associationAnimals}/>
                                    <FormControl component="fieldset" variant="standard" fullWidth margin="normal">
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={currentCat?.contactOtherAnimals}
                                                disabled={readOnly}
                                                onChange={handleChange}
                                                name="contactOtherAnimals"
                                                color="primary"
                                            />}
                                            label="Mag in contact komen met andere katten"
                                        />
                                    </FormControl>
                                    {!isLoading && (!currentCat.kattenziekte || !currentCat.kattenleukemie || !currentCat.niesziekte) && (
                                        <Alert sx={{marginTop: 2}} severity="warning">Vaccinaties zijn verplicht voor een verblijf bij kattenhotel Catland, dit wordt steeds
                                            gecontroleerd bij check-in.
                                            Wanneer vaccinaties niet volledig zijn, zal uw kat geweigerd worden!</Alert>
                                    )}
                                </CardContent>
                                <CardActions style={{
                                    display: 'flex', justifyContent: location.pathname !== "/mijn-profiel" ? 'space-between' : 'right'
                                }}>
                                    {location.pathname !== "/mijn-katten" && (
                                        <>
                                            <Button onClick={goBack} variant="contained" disabled={isLoading}>
                                                Vorige
                                            </Button>
                                            {currentIndex + 1 === cats.length && (
                                                <Button type="submit" variant="contained" disabled={isLoading}>
                                                    Volgende
                                                </Button>
                                            )}
                                            {currentIndex + 1 < cats.length && (
                                                <Button variant="contained" onClick={() => changeCat(1)} disabled={isLoading}>
                                                    Vul de gegevens aan van {cats[currentIndex + 1].name}
                                                </Button>
                                            )}
                                        </>
                                    )}
                                    {!readOnly && location.pathname === "/mijn-katten" && (
                                        <Button onClick={updateCurrentCat} variant="contained" disabled={isLoading}>
                                            Update
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        </Box>
                    </Grid>
                    <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar}/>
                </Grid>)}
        </>
    )
};


export default withAuthenticationRequired(CatInformationForm, {
    onRedirecting: () => <Loading/>,
    returnTo:
        '/nieuwe-reservatie'
});
