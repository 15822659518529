import React from 'react';
import {AppBar, Toolbar, Grid, Typography, Container, useMediaQuery} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import theme from "../../common/Theme";

function TopBar() {
    const drawerWidth = 240;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <AppBar position="fixed" sx={{background: 'transparent', boxShadow: 'none', color: 'black'}}>
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{textAlign: 'center'}}>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: `${ isMobile ? 0 : drawerWidth}px`}}>
                            <EmailIcon color="primary"/>
                            <Typography color="secondary" variant="body1" sx={{ ml: 1, textDecoration: 'none' }}>
                                <a href="mailto:info@kattenhotel)catland.be"  style={{ textDecoration: 'none', color:'#282c34' }}>info@kattenhotel-catland.be</a>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: `${ isMobile ? 0 : drawerWidth}px`}}>
                            <PhoneIcon color="primary"/>
                            <Typography color="secondary" variant="body1" sx={{ml: 1}}>
                                <a href="tel:+32476/70.20.44" style={{ textDecoration: 'none', color:'#282c34' }}>0476/70.20.44</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default TopBar;
